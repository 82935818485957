import React from 'react';
import styled from 'styled-components';



const NoPermissionPage = ({ sideBarWidth }) => {
  return (
    <Container sideBarWidth={sideBarWidth}>
      <Header>
        <Icon
          aria-hidden="true"
          src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20-%20Filled.svg"
        />
        <Title tabIndex={-1}>Bugs</Title>
      </Header>
      <Content>
        <LargeIcon src="https://tp-assets.sfo2.cdn.digitaloceanspaces.com/icons/Icon_Bugs_AccessDenied.svg" />
        <Message>
          You don't have permission to view this page.
        </Message>
        <SubText>
          Before you can view Bugs for this project, you must first be added as a team member. Please contact your project manager if this is unexpected.
        </SubText>
        <ButtonLink href="/dashboard">
          Navigate to Dashboard
        </ButtonLink>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: calc(100% - ${({ sideBarWidth }) => sideBarWidth + 18}px);
  will-change: max-width;
  transition: max-width 0.666s ease-in-out 0s;
  margin-left: auto;
  height: 100%;
`;

const Header = styled.div`
  flex: 1;
  align-items: center;
  display: inline-flex;
  height: 90px;
  width: 100%;
  padding-left: 20px;
  border-bottom: solid 1px lightgray;
`;

const Icon = styled.img`
  margin-right: 10px;
  height: 40px;
  width: 40px;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LargeIcon = styled.img`
  width: 90px;
  margin-bottom: 20px;
`;

const Message = styled.h2`
  width: 500px;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Manrope', sans-serif;
`;

const SubText = styled.p`
  width: 500px;
  margin-bottom: 20px;
  text-align: center;
`;

const ButtonLink = styled.a`
  width: 175px;
  text-align: center;
  height: 48px;
  line-height: 48px;
  background-color: #007bff; /* Example of using styles */
  color: white;
  text-decoration: none;
  border-radius: 5px;
`;
export default NoPermissionPage;
