import React from 'react';
import styled from 'styled-components';
import { AutoSizer, Column, Table } from "react-virtualized";
import SubNavbar from "../common/sub_navbar";
import CheckboxMenu from "../common/checkbox_menu";
import TicketTableHeader from "./ticket_table_header";


const TicketsDashboard = ({
  currentUser,
  tickets,
  loaded,
  ticketCount,
  updateSearch,
  clearInput,
  resetFilters,
  handleCreateNewBug,
  handleTypeChange,
  handleCreatedByUserChange,
  handleAssignedToUserChange,
  search,
  ticketFilterStatus,
  selectedCreatedByUsers,
  selectedAssignedToUsers,
  sortDirection,
  column,
  filterCreatedByUsers,
  filterAssignedToUsers
}) => {

  const getRowClassName = ({ index }) => {
    return index % 2 === 0 ? "evenRow" : "oddRow";
  };

  // const headerRowRenderer = (props) => {
  //   return (
  //     <TicketTableHeader
  //       project={self.state.project}
  //       props={props}
  //       column={self.state.column}
  //       direction={self.state.direction}
  //       sortTickets={self.sortTickets}
  //     />
  //   );
  // }
  return (
    <Container>
      <Header>
        <LeftHeader>
          <NewBugButton
            id="new-bug"
            type="button"
            onClick={handleCreateNewBug}
            className="btn btn-overflow"
          >
            Create New Bug
          </NewBugButton>
          <TicketSearchWrapper id="ticketSearch">
            <SearchIcon aria-hidden="true" className="material-icons prefix">
              search
            </SearchIcon>
            <BugSearchInput
              id="bug-search-input"
              disabled={tickets.length === 0}
              className="browser-default"
              type="text"
              onChange={updateSearch}
              placeholder=" "
              aria-label="Bugs search input field"
            />
            {search !== "" && (
              <button onClick={clearInput} className="link-btn material-icons grey-text">
                close
              </button>
            )}
          </TicketSearchWrapper>
          <StatusText
            aria-label={`Showing ${
              search ? `${tickets.length} out of ${ticketCount} bugs` : `all ${ticketCount} bugs`
            }`}
            role="text"
          >
            {`Displaying ${tickets.length >= 1 ? "1" : "0"}-${tickets.length} out of ${ticketCount == null ? "0" : ticketCount}`}
          </StatusText>
        </LeftHeader>
        {/* Add your SubNavbar, CheckboxMenu, and other components here */}
        <SubNavbar
          flex={[0, 0, 0]}
          pageName=""
          aria-hidden={false}
          containerStyle={{
            height: "70px",
            flex: 3,
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          height="70px"
          maxWidth="100%"
          center
          sectionOneChildren={[
            <CheckboxMenu
              maxWidth="100%"
              key={3}
              count={
                ticketFilterStatus.filter((x) => x)
                  .length
              }
              active={
                ticketFilterStatus.filter((x) => x)
                  .length >= 1
              }
              options={[
                {
                  label: "Open",
                  value: 0,
                  handler: handleTypeChange,
                  checked: ticketFilterStatus[0],
                },
                {
                  label: "Closed",
                  value: 1,
                  handler: handleTypeChange,
                  checked: ticketFilterStatus[1],
                },
                {
                  label: "Fixed",
                  value: 2,
                  handler: handleTypeChange,
                  checked: ticketFilterStatus[2],
                },
                {
                  label: "In Progress",
                  value: 3,
                  handler: handleTypeChange,
                  checked: ticketFilterStatus[3],
                },
                {
                  label: "Won't Fix",
                  value: 4,
                  handler: handleTypeChange,
                  checked: ticketFilterStatus[4],
                },
                {
                  label: "Reopened",
                  value: 5,
                  handler: handleTypeChange,
                  checked: ticketFilterStatus[5],
                },
              ]}
              title="Filter By Status"
              id="1"
              containerMinWidth="160px"
              narrow
              icons={[
                "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
              ]}
            />,
          ]}
          sectionTwoChildren={[
            <CheckboxMenu
              key={2}
              searchEnabled
              maxWidth="100%"
              count={selectedCreatedByUsers.length}
              active={
                selectedCreatedByUsers.length >= 1
              }
              options={[
                {
                  label: "Me",
                  value: currentUser.id,
                  handler: handleCreatedByUserChange,
                  checked:
                    selectedCreatedByUsers.indexOf(
                      currentUser.id
                    ) !== -1,
                },
                {
                  label: "PlusQA Team",
                  value: "pqa",
                  handler: handleCreatedByUserChange,
                  checked:
                    selectedCreatedByUsers.indexOf(
                      "pqa"
                    ) !== -1,
                },
                {
                  label: "Clients",
                  value: "client",
                  handler: handleCreatedByUserChange,
                  checked:
                    selectedCreatedByUsers.indexOf(
                      "client"
                    ) !== -1,
                },
                ...filterCreatedByUsers.map(
                  (user) => ({
                    label: user.label,
                    value: user.value,
                    handler: handleCreatedByUserChange,
                    checked:
                      selectedCreatedByUsers.indexOf(
                        user.value
                      ) !== -1,
                    classList: "user-option",
                  })
                ),
              ]}
              title="Filter By Tester"
              id="2"
              icons={[
                "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
              ]}
            />,
          ]}
          sectionThreeChildren={[
            <CheckboxMenu
              key={3}
              searchEnabled
              maxWidth="100%"
              containerMinWidth="175px"
              count={selectedAssignedToUsers.length}
              last
              active={
                selectedAssignedToUsers.length >= 1
              }
              options={[
                {
                  label: "Me",
                  value: currentUser.id,
                  handler: handleAssignedToUserChange,
                  checked:
                    selectedAssignedToUsers.indexOf(
                      currentUser.id
                    ) !== -1,
                },
                ...filterAssignedToUsers.map(
                  (user) => ({
                    label: user.label,
                    value: user.value,
                    handler: handleAssignedToUserChange,
                    checked:
                      selectedAssignedToUsers.indexOf(
                        user.value
                      ) !== -1,
                    classList: "user-option",
                  })
                ),
              ]}
              title="Filter By Assignee"
              id="3"
              icons={[
                "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters_blue.svg",
                "https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20Filters.svg",
              ]}
            />,
          ]}
        />
        {selectedAssignedToUsers.length +
          selectedCreatedByUsers.length +
          ticketFilterStatus.filter((x) => x).length >= 1 && (
          <ClearFiltersButton onClick={resetFilters} className="btn link-btn">
            Clear All Filters
          </ClearFiltersButton>
        )}
      </Header>

      {loaded === false ? (
        <LoaderWrapper>
          <div id="test-step-bugs-loader" />
        </LoaderWrapper>
      ) : (
        <TicketsTableWrapper>
          <AutoSizer>
            {({ width, height }) => (
              <StyledTable
                width={width}
                height={height}
                headerHeight={25}
                rowHeight={32}
                rowCount={tickets.length}
                rowGetter={({ index }) => tickets[index]}
                sortBy={column}
                sortDirection={sortDirection}
                overscanRowCount={2}
                rowClassName={getRowClassName}
                // headerRowRenderer={headerRowRenderer}
              >
                <Column label="Id" dataKey="id" width={60} />
                <Column label="Priority" dataKey="ticket_priority_id" width={70} />
                <Column label="Title" dataKey="title" width={360} flexGrow={1} />
                <Column label="Status" dataKey="ticket_status_id" width={60} />
                <Column label="Assignee" dataKey="assignee_id" width={100} />
                <Column label="Reporter" dataKey="tester_id" width={100} />
                <Column label="WCAG" dataKey="a11y_id" width={100} />
                <Column label="Created" dataKey="created_at" width={100} />
              </StyledTable>
            )}
          </AutoSizer>
        </TicketsTableWrapper>
      )}
      <NoTicketsWrapper className={tickets.length >= 1 ? "" : "noTickets"} />
    </Container>
  );
};


// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 15px;
`;

const LeftHeader = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: row;
`;

const NewBugButton = styled.button`
  margin-right: 20px;
`;

const TicketSearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchIcon = styled.i`
  position: absolute;
  pointer-events: none;
  left: 6px;
  color: #ababab;
`;

const BugSearchInput = styled.input`
  &.browser-default {
    padding-left: 24px; /* To give space for the search icon */
  }
`;

const StatusText = styled.p`
  white-space: pre;
  align-self: center;
  padding: 0 20px;
`;

const FiltersWrapper = styled.div`
  height: 70px;
  flex: 3;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
`;

const ClearFiltersButton = styled.button`
  flex: 0.2;
  white-space: pre;
  align-self: center;
  color: #519acc;
  cursor: pointer;
`;

const LoaderWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const NoTicketsWrapper = styled.div`
  align-self: center;
  align-content: center;
  margin-left: -29px;
`;

const TicketsTableWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const StyledTable = styled(Table)`
  .evenRow {
    background-color: #ffffff; /* White background for even rows */
  }

  .oddRow {
    background-color: #dedede /* Custom background color for odd rows */
  }

  .ReactVirtualized__Table__row {
    display: flex;
    align-items: center;
  }

  .ReactVirtualized__Table__headerRow {
    background-color: #519acc;
    color: white;
  }
`;


export default TicketsDashboard;
