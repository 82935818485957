import React from 'react';
import styled from 'styled-components';
import QRCodeIcon from "../icons/qr_code_icon";
// import QRCode from "../modals/qr_code";


const TicketsSubNav = ({ currentUser, taskBoardView, toggleTaskboardView, showQRCode }) => {
  
  return (
    <div id="tickets-sub-nav-container">
      <SubNavContainer>
        <FlexContainer>
          <Icon
            aria-hidden="true"
            src="https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Bugs%20-%20Filled.svg"
          />
          <Title tabIndex={-1}>Bugs</Title>
        </FlexContainer>
        {currentUser.role !== 'client' && (
          <QRButton onClick={showQRCode}>
            <QRCodeIcon
              width={20}
              height={20}
              color="#242424"
              svgStyles={{ position: 'relative', top: '2px' }}
            />
          </QRButton>
        )}
        <ViewButton left={true}  aria-hidden="true" aria-label="List View" className="list-view">
          <ViewIcon
            onClick={toggleTaskboardView}
            src={
              taskBoardView
                ? 'https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_List_View.svg'
                : 'https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_List_View_Blue.svg'
            }
            width="36"
            height="36"
            alt="list view icon"
            data-id="list"
          />
        </ViewButton>
        <ViewButton left={false} aria-hidden="true" aria-label="Taskboard View" className="taskboard-view">
          <ViewIcon
            onClick={toggleTaskboardView}
            src={
              taskBoardView
                ? 'https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Taskboard_View_Blue.svg'
                : 'https://plusqa-assets.sfo2.cdn.digitaloceanspaces.com/test-platform/Icons_Taskboard_View.svg'
            }
            width="38"
            height="38"
            data-id="column"
          />
        </ViewButton>

      </SubNavContainer>
    </div>
  );
};

const SubNavContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 13px;
`;

const FlexContainer = styled.div`
  flex: 1;
  align-items: center;
  display: inline-flex;
`;

const Icon = styled.img`
  margin-right: 10px;
  height: 40px;
  width: 40px;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const QRButton = styled.button`
  margin-right: 8px;
  color: #519acc;
  border: solid 1px lightgray;
  height: 32px;
  border-radius: 6px;
  background: none;
  cursor: pointer;
`;

const ViewButton = styled.span`
  display: inline-block;
  border: solid 1px lightgray;
  border-radius: ${({ left }) => (left ? '6px 0 0 6px' : '0 6px 6px 0')};
  height: 32px;
  padding: 0 2px;
  margin-right: ${({ left }) => (left ? '0px' : '10')};
`;



const ViewIcon = styled.img`
  display: inline-block;
  font-size: 44px;
  cursor: pointer;
  vertical-align: bottom;
  position: relative;
  bottom: 4px;
`;

export default TicketsSubNav;
